<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
    <div>
      <vx-card title="Form Settlement">
        <div class="vx-row mt-3">
          <div class="vx-col sm:w-1/2 w-full mb-base">
            <!-- <span class="py-2 h5 font-semibold">Customer Deposit Settlement</span><hr> -->
            <div class="vx-row my-6">
              <div class="vx-col sm:w-1/3 w-full">
                  <span>Territory</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full input-readonly" v-model="detail.territory" readonly />
              </div>
            </div>
            <!-- <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Supplier</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.code" readonly />
                </div>
            </div> -->
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Customer</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.customer" readonly />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Description</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.description" readonly />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <label class="vs-input--label">CN Supplier Value</label>
                    <vs-input class="w-full input-readonly" v-model="detail.value" readonly />
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <label class="vs-input--label">Total Settle Value</label>
                    <vs-input class="w-full input-readonly" v-model="totalUsed" readonly />
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <label class="vs-input--label">Remaining</label>
                    <vs-input class="w-full input-readonly" v-model="totalAvailable" readonly />
                </div>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/1 w-full mb-base">
            <data-table
              :responseData="responseData"
              :propsParams="params"
              @reloadDataFromChild="reloadData"
            >
              <template slot="thead">
                <th>
                  Deposit Data
                </th>
                <th>
                  Deposit Value
                </th>
                <th>
                  Allocation Reference
                </th>
                <th>
                  Allocation Value
                </th>
                <th width="7%">
                  <!-- <vs-checkbox v-model="checkedAll">Settle</vs-checkbox> -->
                   Settle
                </th>
                <th>
                  Settlement Value
                </th>
              </template>
              <template slot="tbody">
                <vs-tr :key="index" v-for="(tr, index) in cashBank">
                  <vs-td>
                    Deposit Number : {{ tr.DepositNumber }}<br/>
                    Deposit Date : {{ dateFormat(tr.DepositDate) }}<br/>
                    Posting Date : {{ dateFormat(tr.PostingDate) }}<br/>
                    Used : {{ tr.TransactionType=="allocation"?formatPrice(tr.Used-tr.PaymentValue):formatPrice(tr.ValueSettled) }}<br/>
                    Available : {{ formatPrice(tr.Available) }}
                  </vs-td>
                  <vs-td>
                    {{ formatPrice(tr.DepositValue) }}
                  </vs-td>
                  <vs-td>
                    {{ tr.Info!=null?tr.Info.reference:"" }}
                  </vs-td>
                  <vs-td>
                    {{ formatPrice(tr.PaymentValue) }}
                  </vs-td>
                  <vs-td>
                    <vs-checkbox
                      v-if="tr.TransactionType != 'CN Supplier'"
                      v-model="tr.checked"
                      @change="handleSettle(tr, index)"
                    >
                    </vs-checkbox>
                  </vs-td>
                  <vs-td>
                    <vue-number
                        v-if="tr.TransactionType != 'CN Supplier'"
                        :customClass="`w-full`"
                        v-model="tr.paid"
                        :masked="true"
                    ></vue-number>
                  </vs-td>
                </vs-tr>
              </template>
            </data-table>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full mb-base">
            <vs-button
                v-if="!viewOnly"
                class="mb-2 mr-2"
                v-on:click="handleSubmit()"
            >
                Submit
            </vs-button>
            <vs-button
                class="mb-2 mr-2"
                color="danger"
                type="border"
                v-on:click="navigateBack()"
              >
                Close
              </vs-button>
          </div>
        </div>
      </vx-card>
    </div>
  </template>
  <script>
    import vSelect from "vue-select";
    import Datepicker from "vuejs-datepicker";
    import moment from "moment";
    import VueNumber from "@/components/vue-number/component.vue";
  
    export default {
      components: {
        Datepicker,
        "v-select": vSelect,
        "vue-number":VueNumber,
      },
      props: {
        selected: Object,
      },
      data() {
        return {
            params: {
              search: "",
              length: 10,
              page: 1,
              order: "desc",
              sort: "id",
            },
            responseData: {},
            cashBank: [],
            dataGet: {},
            viewOnly: false,
            checkedAll: false,
            customerDeposit: {},
            optionMethod: ["Cash", "Giro", "Cheque", "Transfer"],
            detail: {
                customer: "",
                territory: "",
                description: "",
                value: 0,
            },
            allocate: [{
                id: 0,
                reference: "",
                value: 0,
            }],
            header: [
              {
                text: "Deposit Data",
                // value: "code",
                // width: '5%'
              },
              {
                text: "Deposit Value",
                sortable: false,
              },
              {
                text: "Allocation Reference",
                sortable: false,
              },
              {
                text: "Allocation Value",
                sortable: false,
              },
              {
                text: "COA/Value",
                sortable: false,
              },
            ],
        }
      },
      watch: {
        checkedAll() {
          this.checkAll();
        },
        // cashBank: {
        //   handler(newVal) {
        //     newVal.forEach((tr, index) => {
        //       if (tr.checked) {
        //         // console.log(`Row ${index} is checked with deposit number: ${tr.DepositNumber}`, tr);
        //         tr.paid = tr.PaymentValue
        //       } else {
        //         tr.paid = 0
        //       }
        //     });
        //   },
        //   deep: true,
        // },

      },
      computed: {
        totalUsed() {
          let settled = this.dataGet.value_settled

          let total = this.cashBank
              .filter(tr => tr.checked)
              .reduce((acc, tr) => acc + parseFloat(tr.paid.toString().replace(/,/g, "") || 0), 0);
          
          return this.formatPrice(total+settled);
        },

        totalAvailable() {
          let settled = this.dataGet.value_settled

            let total = this.cashBank
                .filter(tr => tr.checked)
                .reduce((acc, tr) => acc + parseFloat(tr.paid.toString().replace(/,/g, "") || 0), 0);
            let available = parseFloat(this.detail.value.toString().replace(/,/g, "")) - parseFloat(total) - parseFloat(settled)

            // let available = parseFloat(depoVal) - parseFloat(used) - parseFloat(allocate)

            return this.formatPrice(available);
        },
      },
      async mounted() {
        console.log(this.selected, "selected data");
        const path = this.$router.currentRoute.path.toLowerCase();
        const id = parseInt(atob(this.$route.params.id));
        if (path.includes("view")) {
          this.viewOnly = true;
        }

        await this.getCustomerDeposit(id)
        this.getGeneralForm()
        await this.reloadData(this.params)

      },
      methods: {
        navigateBack() {
          if (!this.viewOnly) {
            return this.$router.push({
              name: "customer-deposit-settlement.detail",
              params: { id: btoa(this.dataGet.credit_note_supplier_id) },
            });
          } else {
            return this.$router.push({
              name: "customer-deposit-settlement.detail.view",
              params: { id: btoa(this.dataGet.credit_note_supplier_id) },
            });
          }
        },
        handleSettle(tr, index) {
          if (tr.checked) {
            tr.paid = tr.PaymentValue; // If checked, set the paid value to PaymentValue
          } else {
            tr.paid = 0; // If unchecked, reset the paid value to 0
          }
        },
        checkAll() {
          if (this.checkedAll) {
            console.log("true allChecked");
            // this.checked = this.responseData.invoices.map((v) => v.invoice.ID);
            // this.clientCodes = this.responseData.invoices.map((v) => ({
            //   id: v.invoice.ID,
            //   code: v.invoice.client_code,
            // }));
          } else {
            console.log("false allChecked");
            // this.checked = [];
            // this.clientCodes = [];
          }
          // console.log(this.clientCodes);
        },
        check(val, index) {
          if (this.checked.includes(val)) {
            this.checked.splice(this.checked.indexOf(val), 1);
            // const codeIndex = this.clientCodes.findIndex((v) => v.id === val);
            // this.clientCodes.splice(codeIndex, 1);
          } else {
            // this.checked.push(val);
            // const obj = {
            //   id: val,
            //   code: this.responseData.invoices[index].invoice.client_code,
            // };
            // this.clientCodes.push(obj);
          }

          console.log(this.clientCodes, "this.checked");
        },
        async getCustomerDeposit(id) {
            try {
                this.$vs.loading();
                const resp = await this.$http.get(`/api/v2/finance/credit-note-supplier/form/${id}`);
                console.log(resp);
                this.dataGet = resp.data.line;
                // this.customerDeposit = resp.data.cashBank;

                this.$vs.loading.close();
            } catch (e) {
                console.log(e);
                this.$vs.loading.close();
            }
        },
        getGeneralForm() {
            this.detail.customer = "("+this.dataGet.customer_code+") "+this.dataGet.customer_name
            this.detail.territory = "("+this.dataGet.territory_code+") "+this.dataGet.territory_name
            this.detail.description = this.dataGet.description
            if (this.dataGet.value_credit > 0) {
              this.detail.value = this.formatPrice(this.dataGet.value_credit)
            } else {
              this.detail.value = this.formatPrice(this.dataGet.value_debit)
            }

            // this.allocate = []
            // this.dataGet.cashBankLine.forEach(line => {
            //     if (line.TransactionType == "allocation") {
            //         let dataAllocate = {
            //             id: line.ID,
            //             reference: line.Info.reference,
            //             value: this.formatPrice(line.PaymentValue),
            //         }

            //         this.allocate.push(dataAllocate)
            //     }
            // });

            // if (this.allocate.length == 0) {
            //     this.allocate = [{
            //     id: 0,
            //     reference: "",
            //     value: 0,
            // }]
            // }
        },
        async reloadData(params) {
          this.params = params;
          // console.log(this.selectSupplier, "this.selectSupplier");
          // let supplierID = this.selectSupplier.value ? this.selectSupplier.value.id : 0;
          console.log(params, "sadw");
          // console.log(
          //   "getters",
          //   this.$store.getters["operatingUnit/getSelectedTerritoryId"]
          // );
          // const territory_ids =
          //   this.$store.getters["operatingUnit/getSelectedTerritoryId"];
          // let codes = this.invoiceCodes;
          // if (this.invoiceCodes.length > 0) {
          //   codes = this.invoiceCodes.map((data) => data.code);
          // }
          let param = {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            person_id: this.dataGet.customer_id,
            // supplier_id: supplierID,
            // territory_ids: this.territoryIDs,
          }

          if (this.viewOnly) {
            param["type_transaction"] = "CN Supplier"
          }

          console.log(this.viewOnly, "this.viewOnly");

          this.$vs.loading();
          this.$http
            .get("api/v1/customer-deposit-settlement/data-table", {
              params: param
            })
            .then((resp) => {
              console.log(resp);
              if (resp.code == 500) {
                this.$vs.loading.close();
              } else if (resp.code == 200) {
                this.$vs.loading.close();
                this.responseData = resp.data;
                // this.cashBank = resp.data.cashBank
                this.cashBank = resp.data.cashBank.map(item => {
                    return {
                      ...item,
                      checked: false, // Initialize checked as false
                      paid: 0 // Initialize paid value to 0
                    };
                });
              } else {
                this.$vs.loading.close();
              }
            });
        },
        handleSubmit() {
          if (parseFloat(this.totalAvailable.toString().replace(/,/g, "")) < 0) {
            this.$vs.notify({
                title: "Error",
                text: "Available must be greater than 0",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
            });
            return
          }
          let selectedData = []
          let hasError = false;
        
          this.cashBank.filter(item => item.checked).forEach(item => {

            console.log(item.PaymentValue, "item.PaymentValue");
            console.log(item.paid, "item.paid");

            // if (item.PaymentValue < parseFloat(item.paid.toString().replace(/,/g, ""))) {
            //   this.$vs.notify({
            //     title: "Error",
            //     text: "Allocation must be graeter than settled value",
            //     color: "danger",
            //     position: "top-right",
            //     iconPack: "feather",
            //     icon: "icon-check",
            //   });
            //   hasError = true;
            //   return;
            // }

            if (item.Available+item.PaymentValue < parseFloat(item.paid.toString().replace(/,/g, ""))) {
              this.$vs.notify({
                title: "Error",
                text: "Available must be graeter than settled value",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
              hasError = true;
              return;
            }

            let data = {
              ID: item.ID,
              Paid: parseFloat(item.paid.toString().replace(/,/g, "")),
              CashBankID: item.CashBankID,
            };

            selectedData.push(data);
          });

          if (hasError) {
            return;
          }

          console.log(this.cashBank, "this.cashBank");
          
          this.$vs.loading();

          let param = {
            selectedData: selectedData,
            CnSupplierID: this.dataGet.ID,
          }

          this.$http
            .post("/api/v2/finance/credit-note-supplier/settlement", param)
            .then((resp) => {
              this.$vs.loading.close();
              if (resp.code == 200) {
                  this.$vs.notify({
                  title: "Success",
                  text: "",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                  });
                  return this.$router.push({
                    name: "customer-deposit-settlement",
                    // params: { id: btoa(this.dataGet.credit_note_supplier_id) },
                  });
              } else {
                  this.$vs.notify({
                  title: "Error",
                  text: "Failed to create",
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                  });
              }
            })
            .catch((e) => {
              this.$vs.notify({
                  title: "Error",
                  text: "Failed to create",
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
              });
            });
        },
        addField() {
            this.allocate.push({
                id: 0,
                reference: "",
                value: 0,
            });
        },
        removeField(index) {
            // if (this.allocate.length > 0) {
            //     this.$vs.notify({
            //         title: "Error",
            //         text: "Cannot remove",
            //         color: "danger",
            //         position: "top-right",
            //         iconPack: "feather",
            //         icon: "icon-check",
            //     });
            // }

            this.allocate.splice(index, 1);
            console.log(index, "removeFieldCustDepo");
        },
        formatPrice(angka, prefix = "") {
            let number = parseFloat(angka).toFixed(2);
            return number
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        dateFormat(value) {
          return moment(value).format("DD-MM-YYYY");
        },
      }
    }
  </script>
<style scoped>
  .margin-btm {
    margin-bottom: 1rem !important;
  }
  
  .vs-input--input.normal {
    text-align: end;
  }
</style>